<template>
    <div style="text-align: center;font-size: .25rem">
        <h3 style="margin: 1rem 0">
            您的健身房日期到期时间：{{end_time}}
        </h3>
        <div>
            <vue-qr :text="text" :size="150"></vue-qr>
        </div>

<!--        <van-button @click="add">重来一张</van-button>-->
    </div>
</template>

<script>
    import { Toast } from 'vant';
    import vueQr from 'vue-qr'
    import wx from 'weixin-js-sdk'
    import config from "./config";
    export default {
        name: "QRcode",
        data() {
            return {
                imageUrl: require("../assets/color.jpg"),
                text:'',
                end_time:'',

                timer:null
            }
        },
        components: {
            vueQr
        },
        created(){
            this.getData();
        },
        methods:{
            getData(){
                this.$axios.get('wechat/openLockCode').then((res)=>{
                    if(res.data.code==='0000')
                    {
                        this.timer=setInterval(()=>{
                            clearInterval(this.timer);
                            this.timer=null;
                            this.getData();
                        },120000);
                        this.text=res.data.content.qr;
                        this.end_time=res.data.content.end_date;
                    }else{
                        this.text='请重新兑换'
                        Toast.fail(res.data.msg);
                    }
                })
            }
        },
        beforeDestroy() {
            clearInterval(this.timer);
            this.timer=null;
        }
    }
</script>

<style scoped>

</style>
